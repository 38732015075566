function isValidDate(value) {
  const d = new Date(value);

  return d instanceof Date && !isNaN(d);
}

export default defineNuxtPlugin((nuxtApp) => {
  const app = nuxtApp.vueApp;
  app.config.globalProperties.$filters = {
    walletAddress(address) {
      if (!address || address?.length <= 0 || typeof address !== "string")
        return;

      const firstSixChars = address.slice(0, 6);
      const lastFourChars = address.slice(-4);

      return `${firstSixChars}...${lastFourChars}`;
    },
    currency(value, type = "USD") {
      const amount = Number(value);
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: type,
      }).format(amount);
    },
    walletNetwork(network) {
      const runtimeConfig = useRuntimeConfig();
      if (!network || network?.length <= 0 || typeof network !== "string")
        return;

      if (network === "arbitrum") {
        return runtimeConfig.public.NODE_ENV === "production"
          ? "arb1:"
          : "arb4:";
      }

      return runtimeConfig.public.NODE_ENV === "production" ? "arb1:" : "arb5:";
    },
    millisecondsToSeconds(value) {
      if (Number(value) <= 0) return 0;

      return Math.round(Number(value) / 1000);
    },
    firstItemAndLength(array) {
      if (!array || array?.length <= 0) return null;
      if (array.length === 1) return array[0];

      return `${array[0]} +${array.length - 1}`;
    },
    formatDate(
      string,
      { year = "numeric", month = "long", day = "numeric" } = {}
    ) {
      const date = new Date(string);
      const isDate = date instanceof Date && !isNaN(date);
      if (!isDate) return "";

      return new Intl.DateTimeFormat("en-US", { year, month, day }).format(
        date
      );
    },
    dateTable(value) {
      if (!isValidDate(value)) return "";

      const date = new Date(value);

      return new Intl.DateTimeFormat("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
        timeZone: "GMT",
      }).format(date);
    },
    dateHour(value) {
      if (!isValidDate(value)) return "";

      const date = new Date(value);

      return new Intl.DateTimeFormat("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZone: "GMT",
      }).format(date);
    },
  };
  app.provide("filters", app.config.globalProperties.$filters);
});
