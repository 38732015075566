import validate from "/data/runners/one/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.12.1_@types+node@20.17.16_eslint@8.57.1_typescript@5.7.3_vite@5.2.9/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/middleware/auth.global.js";
import manifest_45route_45rule from "/data/runners/one/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.12.1_@types+node@20.17.16_eslint@8.57.1_typescript@5.7.3_vite@5.2.9/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "company-guard": () => import("/data/runners/one/_work/apps-monorepo/apps-monorepo/apps/pay/middleware/companyGuard.js")
}